.order-heading {
    text-align: center;
    /* padding: 2rem; */
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;


    letter-spacing: -0.02em;
    color: #1A1A1A;

}

.clock-icon {
    width: 24px;
    height: 24px;
    left: 0px;
    font-size: 23px;
    color: black;
}

.outer-order-type {
    display: flex;
    justify-content: center;
}

.order-type-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1A1A1A;
    padding: 14px 16px;
    cursor: pointer;
}

.order-type-text:hover {
    border-bottom: 3px solid #2d5c48;
}

.act {
    border-bottom: 3px solid #2d5c48;
}

.main-order-details {
    background-color: #fff;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 2rem
}

.order-number {
    display: flex;
    justify-content: space-between;
}

.order-text {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;

    text-align: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;

}

.order-number-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #666666;
}

.outer-delivery-image {
    display: flex;
    justify-content: center;
}

.delivery-image-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-top: 1rem;
    color: #666666;
}

.delivery-time {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1A1A1A;
}

.custom-width {
    width: 50%;
    margin: auto;
    margin-bottom: 22px;
    border-radius: 14px;
}

.sub-details {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    color: #666666;
}

.sub-total {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    font-family: "Open Sans", sans-serif;
}

.current-order-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #666666;
}

.order-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px;
    gap: 24px;
    width: auto;
    height: 122px;
    background: #FFFFFF;
    /* border: 2px solid #FED301; */
    border-radius: 16px;
    padding-bottom: 23px;
    padding-top: 75px;
}

.order-card:hover {
    /* padding-bottom: 23px; */
    cursor: pointer;
    /* display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 9px;
gap: 24px;
width: auto;
height: 190px;
background: #FFFFFF; */
    border: 2px solid #2d5c48;
    /* border-radius: 16px; */
}

.order-card-left-side {

    padding: 0px;
    gap: 20px;

    width: 131px;
    height: 110px;

}

.order-card-price {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 0px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    padding-bottom: 1px
}

.order-card-time {
    font-style: normal;
    font-weight: 400;
    font-size: 12.8px;
    /* line-height: 20px; */
    color: #999999;


}

.order-card-total-price {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;


    color: #666666;

}

.card-ovel {
    width: 55px;
    height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #D60000;
}

.past-orders {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;


    color: #666666;

}

.reorder {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #D60000;
}
[data-toggle="collapse"].acordeon-toggle:after{
    content: '/`\\';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    transition: all 0.5s;
}


[data-toggle="collapse"].collapsed.acordeon-toggle:after{
    
    
    color:#1A1A1A;
    content: " \\./";
    padding-bottom: 4px;
    padding-top: 4px;
    border-radius: 50%;
}
.scrollModify{
    max-height: calc(calc(80vh - (24px * 2) - (73px + 73px)) - 50px);

overflow-y:auto;
}
@media (max-width: 600px) {
    .mobile-order-div {
        margin-top: 80px !important;
    }
}