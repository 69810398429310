@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Mooli&family=Noto+Sans:wght@400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@500&family=REM:wght@200;300&display=swap');

#round {
    border-radius: 5px;
    border-color: gray;
    height: 10px;
  font-family: "Open Sans", sans-serif;
  }
  #round1 {
    border-radius: 10px;
    border-color: gray;
    height: 42px;
    width: 430px;
  }
  #round2 {
    border-radius: 10px;
    border-color: gray;
    height: 150px;
    width: 100%;
    resize: none;
  }
  
  textarea {
    padding: 6px 8px;
    line-height: 1.5;
    resize: none;
  }
  #txt {
    color: rgb(100, 99, 99);
    font-family: "Open Sans", sans-serif !important;
  }
  
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 600px) {
  .mobile-contact-div {
    margin-top: 90px !important;
  }
}
