.margin_top_mobile_category{
    margin-top: -5%;
    margin-bottom: 5%;
}


@media only screen and (max-width:500px)
{
  .mobl_top_margin{
    margin-top: -1rem;
  }
}

@media only screen and (max-width:400px)
{
  .mobl_top_margin{
    margin-top: 1rem;
  }
}

@media only screen and (max-width:300px)
{ 

  .margin_top_mobile_category{
    margin-top: 36%;
    margin-bottom: 5%;
}

}