
.subtitle{
    text-align: center;
font-family: "Open Sans", sans-serif;
   
}
.image{
    width: 100%;
    align-items: center;
    margin-top: 7px;
    border-radius: 24px;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
    /* margin-left: 10px; */
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;};

                
.about-details{
    width: 50% !important;
    margin: auto;
    text-align: justify;
    margin-top: 3rem;
}
h5{
    font-size: 15px;
    padding-top: 14px
}
.custom-ul{
    padding-left: 50;
}
.p-3 {
    padding: 3rem !important;
    margin-top: 60px !important;
}

@media only screen and (max-width:600px)
{
    .p-3 {
    padding: 0rem !important;
    margin-top: 60px !important;
}
}