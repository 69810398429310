.hideoverflow {
  overflow: hidden;
}

.close-button {
  cursor: pointer;
  margin-left: 530px;
  position: fixed;
  font-size: 15px;
  background-color: white;
  width: min-content;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  overflow: hidden;
}

.modal-content {
  max-height: 90%;
  overflow-y: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
}
.label {
  margin-left: 15px;
}
.checkbox {
  cursor: pointer;
}

.form-check {
  /* margin: 10px 25px 20px 20px; */
}

.button-container {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
}

.accept-button {
  flex-grow: 1;
  width: 80px;
  border-radius: 5px;
  margin-top: 2rem;
  background: #364d49 !important;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
}

.decline-button {
  width: 80px;
  flex-grow: 1;
  border-radius: 5px;
  margin-top: 2rem;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  background-color: #a30606;
  margin-left: 10px;
}
