@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Mooli&family=Noto+Sans:wght@400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@500&family=REM:wght@200;300&display=swap');
.back-arrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.scroll_box{
  max-height: 80vh;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 50px !important;
}

.scroll_box::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar */
}

.scroll_box::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set the background color of the scrollbar track */
}

.scroll_box::-webkit-scrollbar-thumb {
  background: #e64427; /* Set the color of the scrollbar thumb */
}

.main{
  max-height: 100vh;
  height: auto;
}

.login-btn {
  width: 100%;
  margin-bottom: 1rem;
  background: #d1000e !important;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
 font-family: "Open Sans", sans-serif;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  height: 54px;
  padding: 12px 18px;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Primary-color, #364D49);
  background: var(--Primary-color, #364D49);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .login-btn:hover{
    color: white;
  }

  .lgn-signup-btn{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #364D49 !important;
    margin-top: 2rem;
    margin-bottom: 0rem;
    background-color: white !important;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
    color:  #364D49 !important;
    font-family:"Open Sans", sans-serif;
    text-transform: capitalize;
    cursor: pointer;
  }
.back-arrow-circle {
    background: transparent;
    padding: 8px;
    height: 40px;
    border: none;
    text-decoration: underline;
}

.arrow-back-checkout{
  margin-left: -15px;
  margin-top: 15rem;
}

.image-w8r {
  width: 8rem;
}
.atm-card{
    box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
    padding:70px 100px;
}
.customer-name {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-family: "Open Sans", sans-serif;
  line-height: 50px;
  color: #1a1a1a;
}

.information {
  font-size: 20px;
  padding-bottom: 25px;
}

.boxcntnt {
  margin-left: -10px;
  margin-top: 7px;
  color: #666666;
}

.address-btn {
  margin-left: 16rem;
  padding-top: unset !important;
  margin-top: -px !important;
  margin-bottom: 11px !important;
  border: none;
  background: unset;
  color: rgba(214, 0, 0, 1);
  cursor: pointer;
  height: 10px;
}

/* .content-border:hover {
    margin-top: 2px;
    border: 1px solid #6666;
    width: 100%;
    border-radius: 19px;
    background: #f7f7f7f7;
    height: 96px;
    margin-top: 0px;
    margin-bottom: -13px;

} */

.content-border {
  width: 100%;
  border-radius: 19px;
  /* background: #f7f7f7f7; */
  height: 96px;
  margin-top: 0px;
}

.confirm-address {
  font-size: 20px;
}

.checkout-progress-bar li.active:before,
.checkout-progress-bar li.active > span:before {
  background-color: #666;
}


.ps-main-flex-div {
    background-color: white;
    box-shadow:1px 1px 3px 1px rgba(222,222,222,0.75);
     padding:40px 0px;
     height:100%

}
.progresses {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 12rem;
  margin-left: 120px;
}

.line {
  width: 222px;
  height: 3px;
  background: #e6e6e6;
}

.steps-left {
  display: flex;
  border: 3px solid;
  border-color: black;
  background-color: white;
  color: #fff;
  font-size: 14px;
  width: 42px;
  height: 43px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.steps-right {
  display: flex;
  border: 3px solid;
  border-color: #e6e6e6;
  background-color: white;
  color: #fff;
  font-size: 14px;
  width: 42px;
  height: 43px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.circle {
  font-size: 25px;
  /* width: 14px; */
  color: rgb(214, 0, 0);
  /* padding: 5px; */
  padding-top: 2.3px;
}

.listyle {
  list-style: none;
}

.payment-method {
  position: absolute;
  margin-left: 37rem;
  margin-top: 30px;
  font-size: 16px;
}

.informationn {
  position: absolute;
  margin-left: -9rem;
  margin-top: 30px;
  font-size: 16px;
}

.checkoutbtn {
  margin-left: 3rem;
  width: 134px;
  border-radius: 24px;
  border: none;
  height: 57px !important;
  margin-top: 10px;
}

.home-delbtn {
  margin-right: 34px;
  border: none;
  background: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  pointer-events: painted;
}
.heading {
    margin-bottom: 3rem;
    color: #222529;
}
.home-delbtn:hover {
  border: 3px solid;
  border-bottom-color: #fad20c;
  border-top-color: white;
  border-left-color: white;
  border-right-color: white;
  margin-right: 34px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  color: red;
}

.cancelbtn {
  border-radius: 5px;
  width: 84px;
  height: 40px;
  margin-top: 26px;
  border: none;
  background: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 4rem;
  font-family: "Open Sans", sans-serif;
}

.nextbtn {
  border-radius: 5px;
  width: 90px;
  height: 40px;
  margin-top: 26px;
  border: none;
  background: #364d49;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 4rem;
  /* margin-left: 39rem; */
  margin-right: 26px;
  padding: 9px;
  color: white;
  font-family: "Open Sans", sans-serif;
}

.order-detail {
  color: #364d49;
  font-size: 1.9rem;
  font-family: "Open Sans", sans-serif;
  /* margin-left: 24rem; */
}

.location-order-detail {
  color: black;
  /* font-size: 1.9rem; */
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: "Open Sans", sans-serif;
}

.feild-outer {
  width: 75%;
  margin-left: 79px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  height: fit-content;
  margin-top: 45px;
  margin-bottom: 32px;
}

.progress-bar-content {
  display: flex;
  justify-content: space-around;
  width: 108%;
}

.progress-bar-info {
  /* font-family: 'Roboto Condensed'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  margin-left: -102px;
}
.progress-bar-payment {
  /* font-family: 'Roboto Condensed'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}


.instruction-container{
  padding-top: 10px;
  margin-top: -3px;
  padding-bottom: 12px;
  }
  
  .instruction-para{
    color: #212524;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .hr-line-checkout{
    border-top: 1px solid #0f0f0f !important;
  }
  
  .insturction-special-heading{
    color: var(--Neutral-color, #000);
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  }

  .backtoMenuTitle{
    color: #1a1b1a;
font-family: "Open Sans", sans-serif;
font-size: 16px;
cursor: pointer;
font-style: normal;
font-weight: 600;
line-height: normal;
  }

@media (min-width:300px) and (max-width:350px) {
    .atm-card {
        padding: 70px 0px !important;
    }
}
@media (min-width:351px) and (max-width:400px) {
    .atm-card {
        padding: 70px 17px !important;
    }
}
 @media (min-width:401px) and (max-width:450px) {
    .atm-card {
        padding: 70px 42px !important;
    }
 }
 @media (min-width:451px) and (max-width:500px) {
    .atm-card {
        padding: 70px 64px !important;
    }
 }
 @media (min-width:501px) and (max-width:550px) {
    .atm-card {
        padding: 70px 90px !important;
    }
 }
 @media (min-width:551px) and (max-width:600px) {
    .atm-card {
        padding: 70px 110px !important;
    }
 }
@media (max-width:651px) {
    
    .pymnt{
        margin-top: 90px !important;
    }
    .ord-detail{
        margin-top: 20px;
    }
    .ps-main-flex-div {
         padding:20px 0px;
    }
    .progress-bar-content {
    display: flex;
    justify-content: space-between;
  }
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    /* margin-right: 56px; */
    margin-right: 12%;
  }
  .progress-bar-info {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: -23px;
  }
  .location-cart-container-1 {
    width: 100%;
    left: 0px !important;
    max-width: 100% !important;
  }
  .co-main-container {
    margin-top: 10rem;
  }
}


@media (min-width: 991px) and (max-width: 1051px) {
  .nextbtn {
    border-radius: 5px;
    width: 90px;
    height: 37px;
    margin-top: 0px;
    border: none;
    background: #364d49;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 4rem;
    margin-left: unset;
    margin-right: 77px !important;
    padding: 7px;
    margin-bottom: 8rem;
  }

.order-detail {
    color: #364D49;
    font-size: 1.9rem;
    /* margin-top: 6rem; */
    font-family: "Open Sans", sans-serif;
    /* margin-left: 24rem; */
}

.feild-outer {
    /* width: 48%; */
    /* margin-left: 79px; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
    height: fit-content;
    margin-top: 45px;
    margin-bottom: 32px;


}
.checkout-login-button {
  transition: all 0.3s;
  text-transform: uppercase;
  padding: 0.85rem 3.2rem;
  border-radius: 0;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  line-height: 1.429;
  background: #364d49;
  color: white;
  border-radius: 5px;
}


@media (min-width:991px) and (max-width: 1051px) {

    .nextbtn {
        border-radius: 5px;
        width: 90px;
        height: 37px;
        margin-top: 0px;
        border: none;
        background: #364D49;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        margin-bottom: 4rem;
        margin-left: unset;
        margin-right: 77px !important;
        padding: 7px;
        margin-bottom: 8rem;
    }

    .cancelbtn {

        border-radius: 5px;
        width: 90px;
        height: 40px;
        margin-top: 0px;
        border: none;
        background: white;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        margin-bottom: 8rem !important;
        margin-left: 14px;
        font-family: "Open Sans", sans-serif;

    }

}
.checkout-login-button{
    transition: all .3s;
    text-transform: uppercase;
    padding: 0.85rem 3.2rem;
    border-radius: 0;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    line-height: 1.429;
    background: #364D49;
    color: white;
    border-radius: 5px;
}

    }
@media (min-width: 652px) and (max-width:670px) {
  

    .progress-bar-content {
    display: flex;
    justify-content: space-between;
  }
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-right: 78px;
    /* margin-right: 14%; */
  }
  .progress-bar-info {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: -23px;
  }
}
@media (min-width: 671px) and (max-width:699px) {
    .atm-card{
        padding:70px 20px;
    }

    .progress-bar-content {
    display: flex;
    justify-content: space-between;
  }
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-right: 14rem;
    /* margin-right: 14%; */
  }
  .progress-bar-info {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: -23px;
  }
}

@media (min-width: 768px) and (max-width: 854px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-right: -17px;
  }
}
@media (min-width: 856px) and (max-width: 870px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
  }
}
@media (min-width: 873px) and (max-width: 944px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    margin-right: 8%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
  }
}
@media (min-width: 945px) and (max-width: 990px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    margin-right: 15%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
  }
}
@media (width: 912px) {
  .progress-bar-payment {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: 238px !important;
  }
  .progress-bar-info {
    /* font-family: 'Roboto Condensed'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    margin-left: -24px;
  }
}

@media (min-width: 450px) and (max-width: 699px) {
  .progresses {
    display: flex;

    justify-content: start;

    align-items: center;

    margin-right: 3rem;

    margin-left: 12px;

    /* width: auto; */
  }
}
/* Payment css */
.lines {
  width: 222px;
  height: 3px;
  background: #121111;
}

.steps-rightt {
  display: flex;
  border: 3px solid;
  border-color: black;
  background-color: white;
  color: #fff;
  font-size: 14px;
  width: 42px;
  height: 43px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.steps-leftt {
  display: flex;
  border: 3px solid;
  color: rgb(214, 0, 0);
  font-size: 14px;
  width: 42px;
  height: 43px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.tick {
  font-size: 41px;
  color: rgb(214, 0, 0);
  padding-top: 2.3px;
}

.credit-cod {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  margin-top: 7px;
}

.credit-border {
  /* border: 1px solid rgba(204, 204, 204, 1); */
  /* width: 38px; */
  height: 38px;
}

.credit-icon {
  padding-top: 3px;
  display: block;
  max-width: 100%;
  height: auto;
  padding-left: 7px;
}

.cash-icon {
  padding-top: 7px;
  display: block;
  max-width: 100%;
  height: auto;
  padding-left: 6px;
}

.credit-cod-content {
    /* padding-left: 20px; */
    padding-right: 28px !important;
}

.payment-text {
  font-size: 20px;
  margin-left: 1rem;
}

.card-border {
    /* border: 1px solid #6666; */
    width: 100%;
    border-radius: 4px;
    /* background: #f7f7f7f7; */
    height: 96px;
    margin-top: 0px;
    margin-bottom: -36px;
}
.add-card {
  color: rgba(214, 0, 0, 1);
}

.card-btn {
  padding-top: unset !important;
  margin-top: -61px !important;
  margin-bottom: 11px !important;
  border: none;
  background: unset;
  color: rgb(145, 123, 123);
  cursor: pointer;
  height: 10px;
  margin: auto;
  padding-right: 16px;
}

.cart-quantity {
  background-color: #ffffff;
  height: 39px;
  width: 39px;
  border-radius: 12px;
  padding-top: 10px;
  color: #000000 !important;
}

.cart-checkout-text {
  margin-left: 49px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
font-family: "Open Sans", sans-serif;

}

.cart-final-price {
  /* font-family: 'Roboto Condensed'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: white;
  margin-left: 33px;
  font-family: "Open Sans", sans-serif;

}
.cart-btn-outer
{
    /* position: relative;
    left: 70px; */
}
.place-order-btn{
  border-radius: 8px !important;
  background: #d1000e !important;
    color: white;
    border: none;
    box-shadow: none;
}

.place-order-btn:hover{
  color: white;
}
.cart-btn {
border-radius: 100px;
height: auto;
margin: auto;
background-color: #fed301;
padding: 6px 14px;
color: #FFF;
font-family:"Open Sans", sans-serif;
text-transform: none;
font-size: 16px;
font-style: normal;
font-weight: 500;
position: sticky;
bottom: 0px;
z-index: 1;
z-index: 1;
}

hr{
    margin: 0px;
}
.cart-product-name {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #0f0f0f;
 margin-left: 10px !important;
    font-family: "Open Sans", sans-serif;
  }
input[type='radio'] {

    -webkit-appearance:none;

    width:19px;

    height:19px;

    border:1px solid rgba(204, 204, 204, 1);

    border-radius:50%;

    outline:none;

  width: 19px;

  height: 19px;

  border: 1px solid rgba(204, 204, 204, 1);

  border-radius: 50%;

  outline: none;

  /* box-shadow:0 0 5px 0px gray inset; */
}

input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}

input[type="radio"]:before {
  content: "";

  display: block;

  width: 60%;

  height: 60%;

  margin: 20% auto;

  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: black;
}

@media (min-width: 992px) and (max-width: 1534px) {
  .cart-btn-outer {
    width: auto !important;
    margin-left: 8rem !important;
    margin-right: 11rem !important;
  }
}

/* @media (min-width: 375px){
    .cart-btn-outer{
        width: auto !important;
    /* margin-left: 8rem !important; */
/* margin-right: 2rem !important; */
/* } */
/* }  */
/* cartDetail.css */
.order-detail {
  color: #000000;
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
font-family: "Open Sans", sans-serif;;
  /* margin-left: 24rem; */
}
.location-order-detail {
  color: black;
  font-size: 27px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: -0.02em;
  text-align: left;

  /* margin-left: 24rem; */
}

.location-order-detail1 {
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  /* line-height: 32px; */
  letter-spacing: -0.02em;
  font-family: "Open Sans", sans-serif;

  /* text-align: left; */
}

.sub-details {
  /* font-family: 'Roboto Condensed'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #666666;
}

.location-cart-sub-details {
  font-family: "Open Sans", sans-serif;
  /* font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  position: relative;
  top: 8px;
  /* line-height: 24px; */
  /* identical to box height, or 150% */

  /* display: flex; */
  /* align-items: center; */

  color: black;
}
.ndsub-details {
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 20px;
  /* identical to box height, or 156% */

  /* display: flex; */
  align-items: center;

  /* Neutral/50 */

  color: #808080;
}
.location-cart-product-name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: black;
  /* margin: 5px auto; */
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: "Open Sans", sans-serif; */
}

.location-ndsub-details {
  /* font-style: normal; */
  list-style-type: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 8px;
  /* padding-left: 20px; */
  margin-left: 26px;
  /* identical to box height, or 156% */
  /* font-family: Arial, Helvetica, sans-serif; */
  /* display: flex; */
  align-items: center;

  /* Neutral/50 */

  color: #808080;
}

.location-ndsub-details li::before {
  content: "+"; /* Use the plus symbol as the content */
  /* display: inline-block; */
  width: 1em; /* Adjust the width to control spacing */
  margin-left: -1em; /* Offset to the left to make space for the plus symbol */
}

.sub-total {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: #1a1a1a;
}
.location-sub-total {
font-family: "Open Sans", sans-serif;
  font-weight: 200;
  font-size: 16px;
  margin: 5px 0px 5px 0px;
  /* line-height: 28px; */
  /* identical to box height, or 140% */
font-weight: 600;
  /* display: flex;
  align-items: center; */
  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: black;
}

.location-sub-total-right {
font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin: 5px 0px 5px 0px;
  letter-spacing: -0.02em;
  color: black;
}
.location-subtotal-price {
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  font-size: 16px;
  color: #666666;
}

.Vat-price {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family:"Open Sans", sans-serif;
  font-weight: 200;
  font-size: 16px;
  color: #666666;
  /* margin: 0.5rem; */
}
.location-total-price {
font-family: "Open Sans", sans-serif;

  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: black;
}
.location-total {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 140% */
  letter-spacing: -0.02em;

  /* Neutral/90 */

  color: black;
}

.clock {
  width: 24px;
  height: 24px;
  left: 0px;
  font-size: 30px;
  color: black;
}

.body-content {
  width: 85%;
  background-color: rgb(247, 247, 247);
  box-shadow: rgb(0 0 0 / 8%) 0px 16px 24px;
  /* margin-top: -46px; */
  padding: 20px;
  margin-left: 10rem;
  height: 192vh;
}
@media (width: 991px) {
  .body-content {
    width: 75% !important;
    background-color: rgb(247, 247, 247);
    box-shadow: rgb(0 0 0 / 8%) 0px 16px 24px;
    margin-top: -45px;
    padding: 0px;
    margin-left: 77px !important;
    height: fit-content;
  }
}
.product-modal-special {
  /* font-family: 'Roboto Condensed'; */

  font-style: normal;

  font-weight: 700;

  font-size: 20px;

  line-height: 28px;

  display: flex;

  align-items: center;

  letter-spacing: -0.02em;

  color: #1a1a1a;

  margin: 0px;

  margin-left: 1rem;
}
.product-modal-more-optional {
  font-family: "Open Sans", sans-serif;

  font-style: normal;

  font-weight: 400;

  font-size: 16px;

  line-height: 24px;

  display: flex;

  align-items: center;

  text-align: right;

  color: rgb(13, 37, 49);

  position: relative;

  left: 190px;
}
.product-modal-special-ins {
  /* 
        font-family: 'Roboto Condensed'; */

  font-style: normal;

  font-weight: 400;

  font-size: 16px;

  line-height: 24px;

  margin-left: 1rem;

  color: #666666;
}
.text-area-body {
  outline-color: red;
  margin-top: 41px;
  width: 100%;
  border-radius: 16px;
}
.text-area-body:focus {
  border-color: red;
  outline-style: none;
}

/* //order place modal */
.order-placed-modal {
  padding-top: 5%;
  padding-bottom: 2%;
}
.cart-button {
  background-color: #c41000 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.cart-button:hover {
  background-color: #c00a27 !important;
}

.cart-container {
  position: relative;
  top: 10px;
  margin: 0px 0px 0px 100px;
  padding: 0px;
  /* margin-left: 150px; */
}

.location-cart-container-1 {
  /* background-color: white; */
  background-color: white;
  /* border-radius: 4px; */
  /* box-shadow: 1px 1px 3px 1px rgba(222, 222, 222, 0.75); */
  width: 100%;
  /* max-height: 80%; */

  /* width: 100%; */
  /* padding: 0px 80px 10px 80px; */
}

.location-cart-container {
  position: relative;
  /* left: -15px; */
  /* top: 30px; */
  overflow-y: auto;
  /* min-height: 80vh;
  max-height: 80vh; */
  /* margin: 0px 0px 0px 100px; */
  padding:15px 35px;
  /* height: calc(100% - 100px); */
  /* margin-left: 20px; */
}
.location-cart-extra {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin: auto;
}

.icon_back_menu{
  position: absolute;
  left: 26%;
}

.arrowBacktoMenu{
  color: #4D5150;
  font-size: 20px;
  margin-right: 10px;
}


@media only screen and (min-width:766px) and (max-width:820px)
{
  .icon_back_menu{
    left: 19% !important;
  }
}

@media only screen and (max-width:600px)
{
  .cart-product-name{
    font-size: 16px;
    white-space: nowrap;
  
  }

  .icon_back_menu{
    left: 5% !important;
  }

  .arrowBacktoMenu{
  
    font-size: 18px;

  }


  .place-order-btn{
    
  }
  .button_wrapper_box{
    display: flex;
width: 100%;
margin:auto;
padding: 16px 16px;
justify-content: center;
align-items: center;
border-radius: 10px 10px 0px 0px;
background: #ffffff;
box-shadow: 0px -6px 18px 0px rgba(80, 79, 79, 0.25);
position: fixed;
bottom: 0%;
height: 80px;
left: 0%;
z-index: 999999;
  }

  .cart-btn{
padding: 10px 14px;
  }

}