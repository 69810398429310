
.container-sidebar{

}

.icon-container{
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
}

body{
    position: relative;
}



.sideBar-container{
    background-color: white;
    width: 322px;
    height: 100vh;
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 999999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 24px 24px;

}

.top_bar{
    display: flex;
    justify-content: space-between;
    padding-top: 53px;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 15px;
}

.signIn-btn{

background-color: #d1000e;
border: none;
padding: 8px 24px ;
border-radius: 10px;
color: white;
font-family: "Open Sans", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 7px;
}

.signIn-btn:hover{
    color: #FFF;
}

.sign-btn{
    color: black;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: transparent;
}

.sign-btn:hover{
    color: #364D49;
}

.close-sidebar{
    color: var(--Neutral-color, #020202);
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background-color: transparent;
    cursor: pointer;
}


.content_wrapper_link{
    padding: 10px 16px;
}

.content_left_text{
    color: var(--neutral-neutral-200, #A2A3A5);
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: normal;
}

.content_text_right:hover{
    color: var(--neutral-neutral-200, #A2A3A5) !important;
}

.content_text_right{
    color: var(--neutral-neutral-200, #A2A3A5);
font-family: "Open Sans", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}

.content-left-active{
    color: var(--Neutral-color, #212524);
    font-family: "Open Sans", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.content_right_active{
    color: var(--Primary-color, #141414);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    font-weight: 500;
}

.content-right-disable{
    color: var(--Primary-color, white);
    font-family: Glacial Indifference;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: not-allowed;
    pointer-events: none;
}

.content-right-disable:hover{
    color: white;
}

.content_right_active:hover{
    color: var(--Primary-color, #364D49) !important;
}

.logout_text{
    color: #000;

font-family: "Open Sans", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
}

.logout_icon{
    color: #364D49;
    font-weight: 800;
    font-size: 20px;
}

.footer_sidebar{
    position: absolute;
    bottom: 0%;
    padding: 0px 15px;
}
.footer_sidebar span{
    color: #000;

font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.disabled{
    pointer-events: none;
    cursor: not-allowed;
}
.profile_container{
    background-color: #000000;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_icons{
    color: white !important;
}

.user_name{
    color: var(--Primary-color, #000000);
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.user_email{
    color: var(--grey-grey-200, #989B9A);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.close-btn-modal{
    position: absolute;
    right: 18px;
    top: 5%;
    background-color: #000;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.close-btn-modal-open-hours{
    position: absolute;
    right: 18px;
    top: 2%;
    background-color: #000;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}



.hour-title{
    color: #000;
    padding-top:10px;
    padding-bottom: 0px;
font-family:"Open Sans", sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.custom-hours-padding{
    padding: 10px 0px;
}

.hr-line-modal{
    padding-bottom: 5px;
    margin-top: -10px;
    color: #c7c7c7;
    border-top: 2px solid rgb(95, 95, 95);
    width: 500px;
    margin-left: -13.5%;
}

.hr-store-modal{
    padding-bottom: 5px;
    margin-top: -10px;
    color: #c7c7c7;
    border-top: 2px solid rgb(95, 95, 95);
    max-width: 590px;
    margin-left: -13.5%;
}

.text-dark{
    border-top: 1px solid rgb(105, 105, 105);
}

.hr-line-modal-contact{
    padding-bottom: 5px;
    margin-top: -10px;
    color: #222121;
    border-top: 2px solid rgb(61, 61, 61);
    width: 560px;
    margin-left: -7%;
}

.hr-line-modal-contact-down{
    margin-top: 30px;
    color: #222121;
    border-top: 2px solid rgb(87, 86, 86);
    width: 504px;
    margin-bottom: 10px;
}

.cookeies-button{
    display: flex;
height: 36px;
padding: 8px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
border-radius: 10px;
background: #FFE3DE;
color: #d1000e;
cursor: pointer;

font-family: "Open Sans", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px;
border: none;
}

.cookies_multi_btn{
    gap: 25px;
    margin-bottom: 15px;
}

.cookeies-button-1{
    display: flex;
    height: 36px;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 10px;
    background: #d1000e;
    color: #fff;
    cursor: pointer;
    
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border: none;
}


.cookeies-button-2{
    display: flex;
height: 36px;
padding: 8px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
border-radius: 10px;
background: #fff;
color: #d1000e;
cursor: pointer;

font-family: "Open Sans", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px;
border: 1px solid #d1000e;
}

.cookeies-text{
    color: #605A5A;

font-family: "Open Sans", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.button_gap{
    gap: 4px;
    margin-left: -19px;
}

.buttonlogoutgap{
    margin-left: -8px;
    gap: 20px;
}

.lang-reset{
    display: flex;
    width: 150px;
    height: 44px;
    padding: 10px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
border: 1px solid var(--Neutral-color, #d1000e);
color: var(--Neutral-color, #d1000e);
font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
background-color: white;
}


.lang-save{
    display: flex;
    width: 150px;
    height: 44px;
    padding: 10px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
background: var(--primary-primary-500, #d1000e);
color: #FFF;
font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: 1px solid #d1000e;
}

.lang-text{
color: var(--Neutral-color, #212524);
font-family: "Open Sans", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.radio-lang{
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid black; /* Black border */
    background-color: white; /* White background */
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
}

.lang-subheading{
    color: var(--Neutral-color, #212524);
font-family: "Open Sans", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: -17px;
}

.lang-heading{
    margin-left: -17px;
    color: var(--Neutral-color, #212524);
font-family: "Open Sans", sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.logout-text{
    color: var(--Neutral-color, #212524);
text-align: center;
font-family: "Open Sans", sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.lang-reset-specail{
    display: flex;
width: 200px;
padding: 16px 90px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 14px;
border: 1px solid var(--Neutral-color, #d1000e);
color: var(--Neutral-color, #d1000e);
font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
height: 50px;
background-color: white;
}


.lang-save-special{
    height: 50px;
    display: flex;
width: 200px;
padding: 16px 90px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 14px;
background: var(--primary-primary-500, #d1000e);
border: none;
color: #FFF;
font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;

line-height: normal;
}

.dropdown_sidebar_store{
    border-radius: 10px;
    border: 1px solid #FEF3F1;
    background: #FEF3F1;
    height: 50px;
    border-radius: 10px;
    padding-left: 0px;
    margin-left: 0%;
    max-width: 512px;
    padding: 0px 16px;
}

.dropdown_branch{
    background: #d1000e;
    max-width: 512px;
    z-index: 999999;
    height: 250px;
    overflow-y: auto;
    padding: 10px 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* border-radius: 6px; */
   font-family: "Open Sans", sans-serif;
   border: 1px solid #BABBBB;
   margin-left: 0%;
  margin-top: -3%;
  margin-bottom: 10px;
  transition: height 3s ease-in-out;
}

.dropdown_branch.opened {
    max-height: 250px;
    transition: 3s ease-in-out;
  }

.storeName-branch {
    background-color: white;
    padding: 0px 20px;
    border-radius: 5px;
}

.storeName-branch:hover  {
    background-color: #FFE3DE;
    color: white !important;
}
.storeName-branch:hover > .content_right_active {
    color: #d1000e !important;
}


.storeName-branch span{
    font-family: "Open Sans", sans-serif;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    padding: 8px 0px;
}

.storeName-branch span:hover{
    color: white !important;
}

.dropdown_text{
    color: #000;

font-family: "Open Sans", sans-serif;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.dropdown_text_view{
    color: #212524;

font-family: "Open Sans", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
}


.phone-text{
    color: #FFF;

font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 27px;
}
.main-content-div{
    margin-left: 15px;
    margin-bottom: 10px;
}
.activeStoreName{
    border-radius: 10px;
    border: 1px solid #d1000e;
    background: #d1000e;
    height: 50px;
    /* border-radius: 10px; */
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 0px;
    margin-left: 0%;
    max-width: 512px;
    padding: 0px 16px;
    color: white !important;

}

.modal-stores-cross{
    right: 3% !important;
}

.storeActiveText{
    color: white ;
}

.hideScrollbar::-webkit-scrollbar {
    width: 6px; 
    
  }
  
  .hideScrollbar::-webkit-scrollbar-thumb {
    background-color: #a19f9f; 
    border-radius: 6px;
   
  }
  
  .hideScrollbar::-webkit-scrollbar-track {
    background-color: #ffffff; 
    display: none;
   
  }

  .header-sticky{
    position: sticky;
  }


  .custom-store-modal{
margin-left: 10px;
overflow-y: scroll;
max-height: 380px;
padding: 0px 24px;
overflow-x: hidden;
  }
  
  .custom-store-modal::-webkit-scrollbar {
    width: 5px; 
    
  }
  
  .custom-store-modal::-webkit-scrollbar-thumb {
    background-color: #a19f9f; 
    border-radius: 6px; 

  }
  
  .custom-store-modal::-webkit-scrollbar-track {
    background-color: #ffffff; 
 
  }


@media only screen and (min-width:766px) and (max-width:991px)
{
    .sideBar-container{
        left: 0%;
    }

    .dropdown_sidebar_store{
width: 418px;
    }
    .activeStoreName{
        width: 418px;
    }
    
    .dropdown_branch{
        width: 418px;
    }
}

.hours-text-active-day{
    color: var(--Neutral-color, #212524);
font-family: "Open Sans", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.hours-text-active-hour{
    color: var(--Neutral-color, #212524);
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.hour-text-days{
    color: #000;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.modal_scroll{
    overflow-y: scroll;
}

.hours-text-time{
    color: #000;

    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.custom_modal_hour_top{
    margin-top: 1.3rem;
    padding: 0px 0px;
}

.custom_modal_hour_top_lang{
    margin-top: 2rem;
}

@media only screen and (max-width:600px)
{

    .custom-store-modal{
        margin-left: -5%;
    }

    .dropdown_sidebar_store{
        width: 280px;
        margin-left: 0%;
    }

    .activeStoreName {
        width: 280px;
        margin-left: 0%;
    }

    .phone-text{
        font-size: 12px;
    }

    .dropdown_branch{
        width: 280px;
        margin-left: 0%;
    }

    .lang-save-special{
        width: 130px;
    padding: 16px 63px;
    }

    .lang-reset-specail{
        width: 130px;
    padding: 16px 63px;
    }

    .sideBar-container{
        left: 0%;
        background-color: white;
        width:100vw;
        height: 100vh;
        position: absolute;
        z-index: 99999;
        top: 0%;
        right: 0%;
    }

    .content_wrapper_link{
        margin-bottom: -5px;
    }

    .footer_sidebar{
bottom: 2%;
    }

    .buttonlogoutgap{
        margin-left: -20px;
        gap: 8px;
    }

    .hr-line-modal{
        width: 350px;
        margin-left: -21%;
    }

    .hr-line-modal-contact{
        width: 357px;
        margin-left: -11%;
    }
    .hr-line-modal-contact-down{
        width: 300px;
    }
    .hour-title{
        font-size: 20px;
    }
}

