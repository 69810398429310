@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Mooli&family=Noto+Sans:wght@400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@500&family=REM:wght@200;300&display=swap');
section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width: 100%;
  /* border-bottom: 1px solid lightgrey; */
}

section:nth-child(even) {
  flex-direction: row-reverse;
}
.product-image {
    width: 120px;
    height: 82px;
    margin-top: 20px;
    float: right;
    object-fit: cover;
}
.hm-featured-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  padding-top: 3rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #010101;
font-family: "Open Sans", sans-serif;
  margin-bottom: 15px !important;
}

.hm-featured-heading-over{
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  padding-top: 0rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #010101;
font-family: "Open Sans", sans-serif;
  margin-bottom: 25px !important;
}

 .mbl-menu-cntr {
    margin-top: 11% !important;
  }
/* section img,
section div {
	height: auto;
	width: 100%;
} */

/* section img {
	width: 50%;
} */

/* section div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 1em;
} */
.hm-card-custom {
  box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
  padding:4px 10px 0px 10px;
  border-radius: 4px;
  /* max-height: 150px !important; */
  height: 100%;
  border: 1px solid #edebeb;
}
@media screen and (max-width: 900px) {
  section,
  section:nth-child(even) {
    flex-direction: column;
  }

  section img {
    height: auto;
    width: auto;
    max-width: 100vw;
  }
}
.cart-parent-class {
  padding: 25px 5px; 
  
}

.cart-sticky {
  box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
  position: sticky;
  top: 150px;
  height: calc(100vh - 150px);
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-radius: 4px;
}
.hm-produc-div {
  background: white;
  border-radius: 6px;
box-shadow: 1px 1px 1px 1px #8d928d;
}

.paddingExtraproducts{
  padding-top: 5%;
}


@media only screen and (min-width:766px) and (max-width:991px){
  .hm-featured-heading-over{
    padding-top: 11rem !important;
     
  }
}

@media only screen and (min-width:766px) and (max-width:800px)
{
  .row-tablet-width{
   max-width: 1000px;
   width: 760px !important;
  }
}

@media only screen and (min-width:805px) and (max-width:991px)
{
  .row-tablet-width{
    max-width: 1000px;
    width: 800px !important;
   }

   .mbl-menu-cntr{
margin-top: 17% !important;
   }
}

@media only screen and (min-width:800px) and (max-width:850px)
{
  .margin_left_tab{
    margin-left: 50%;
      }
      .mbl-menu-cntr{
        margin-top: 18% !important;
           }
}

@media only screen and (min-width:766px) and (max-width:799px)
{

  .mbl-menu-cntr{
    margin-top: 20% !important;
       }

  .margin_left_tab{
    margin-left: 49%;
      }
}

@media (max-width: 600px) {
  .mbl-menu-cntr {
    margin-top: 41% !important;
  }

  .paddingExtraproducts{
    padding-top: 10%;
  }
}