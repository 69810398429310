.outer-menu-section {
  background: #f7f7f7 !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.menu-selection-left {
  top: 64.22%;
  background: #d60000;
  border-radius: 24.5364px;
  display: flex;
  justify-content: space-between;
}
.menu-text {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;

  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-top: 76px;
  padding-left: 40px;
}
.menu-text p {
  margin-left: 4px;
}
.menu-btn {
  padding: 5px 11px;
  grid-gap: 8px;
  gap: 8px;
  width: auto;
  height: 45px;
  background: #fed301;
  border-radius: 100px;
  border: none;
  cursor: pointer;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}

.menu-right {
  position: relative;
  top: 18px;
  right: 4%;
}

.menu-selection-right {
  border-radius: 24.5364px;
  display: flex;
  justify-content: space-between;
}
.right-text {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #364D49;
  padding-top: 14%;
  padding-left: 6%;
  padding-bottom: 10.5%;
}
.left-image {
  margin: auto;
  padding-top: 4.1rem;
}
.menu-image {
  width:60%;
  margin-left:40px;
  margin-top:38px
}
.download-app {
  display: flex;
}

@media only screen and (min-width: 320px) and (max-width: 414px) {
  .menu-text {
    padding-top: 32px;
    font-size: 21px;
  }
  .menu-btn {
    height: 51px;
  }
  .right-text {
    font-size: 20px;
    padding-bottom: 2.6%;
  }
  .download-app {
    display: block;
  }
  .download-app img {
    padding: 1rem;
    margin-left: 0px !important;
  }
  .left-image img {
    height: 197px;
  }
  .menu-image {
    width: 100%;
    height: 197px;
    margin-left: 0px;
    margin-top: 36px;
}
    }
  .menu-selection-left {
    margin-bottom: 1rem;
  }

@media only screen and (min-width: 500px) {
  .menu-selection-left {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .menu-text {
    padding-top: 32px;
    font-size: 23px;
  }
  .menu-btn {
    height: 51px;
  }
  .download-app img {
    width: 48%;
  }
}
@media only screen and (min-width: 540px) {
  .menu-selection-left {
    margin-bottom: 1rem;
  }
}
