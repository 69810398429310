.mode-button {
    cursor: pointer;
    font-size: 14px;
    background: none;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px solid #d1000e;
    color: var(--Neutral-color, #212524);
font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.mode-button:disabled {
    font-weight: bold;
    background: #d1000e;
    color: #fff;
font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.md-language-bar {
    display: flex;
    text-align: left;
    align-items: center;
}
.lb-p {
    font-size: 14px;
    color: #070707 !important;
}
.md-container {
    max-width: 149px;
    text-align: right;
    position: relative;
    top: -5px;
}


@media only screen and (max-width:600px){
    .mode-button:disabled{
        font-size: 14px;
      }

      .mode-button{
        font-size: 14px;
      }
}


@media only screen and (min-width:406px) and (max-width:600px)
{
    .md-container {
right: 50%;
}
}


@media only screen and (min-width:385px) and (max-width:405px)
{
.md-container {
right: 81%;
}
} 


@media only screen and (min-width:320px) and (max-width:380px)
{
    .md-container {
right: 92%;
}
}


