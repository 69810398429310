.notFoundContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.customizeBtn{
    padding: 0.7em 3em !important;
    border-radius: 25px !important;
    text-transform: capitalize;
    font-weight: inherit;
    font-size: 15px !important;
}
.paragraphDiv{
    text-align: center;
    width: min(69%, 100%);
    font-size: 15px !important;
}
