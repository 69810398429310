@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Mooli&family=Noto+Sans:wght@400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@500&family=REM:wght@200;300&display=swap');
.main-outer-category {
  background-color: #f7f7f7;
  margin-top: -27px;
  padding: 0px 11px;
}

.sticky-subheader {
  display: none;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: hidden;
  /* overflow-x: auto; */
  background: #f7f7f7;
  padding-left: 10%;
  padding-right: 10%;
  color: #000000;
  width: 90%;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 2;
}

.outer-category {
  width: 91%;
  height: 100px;
  overflow-x:hidden;
  scroll-behavior: smooth;
  /* justify-content: center; */
  top: -35px;
  position: relative;
  z-index: 1;
  left: 50px;
}

.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  /* position: absolute; */
  width: 100%;
  height: 56px;
}

.single-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.inner-single-category {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
  gap: 16px;
  /* background-color: black; */
  /* height: 45px; */
  width: auto;
  /* background: #FED301;
    border: 1px solid #FED301; */
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-size: medium;
  margin: 4px;
  margin-left: 400;

  /* margin-bottom: 200px; */
}
.custom_bg {
  background-color: #171818 !important;
  border-color: #171818 !important;
}

.inner-single-category span {
  font-family: "Open Sans", sans-serif !important;
 
}
.inner-single-category :hover a {
  background-color: #fed301;
}

.active-item {
  background-color: #fed301 !important;
}

/* .inner-single-category :hover{
      background-color: #FED301 ;
    } */
.img-inner-category {
  width: 48px;
  height: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.category-product-name {
  width: auto;
  height: 24px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 37px;
  display: flex;
  color: #fff;
}

.bg {
  background-color: #2d5c48;
}

.bg-white {
  background-color: white;
}

.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  height: 56px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.category-left-next {
  left: -webkit-calc((93.3vw - 1160px)/2);
}

.category-left-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  left: 20px;
  background: #fff !important;
  border-radius: 50%;
  position: relative;
  top: 14px;
  transform: translateY(-50%);
  height: 34px;
  /* z-index: 2; */
}

.category-left-arrow .fa {
  color: #2d5c48;
  font-size: 30px;
}

/* .category-right-next{
    
        right: -webkit-calc((91vw - 1160px)/2);
      
      } */

.category-right-arrow .fa {
  color: white;
  font-size: 30px;
}

.category-right-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  background: #fff;
  font-weight: 500;
  position: relative;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 1;
  right: -98%;
  /* left: 17px; */
  top: -120px;
}


.sticky-subheader-outer {
    width: 100%;
    height: 63px;
    background-color: #171818;
    color: white;
    position: sticky;
    position: relative;
    top: 80px;
    left: 0;
    border-radius: 6px;
    z-index: 1;

}

.sticky-subheader-outer-over{
  width: 100%;
  height: 63px;
  background-color: #070707;
  color: white;
  position: sticky;
  position: relative;
  top: 137px;
  left: 0;
  border-radius: 6px;
  z-index: 1;
}


.paddingCategoryExtra{
  top: 135px !important; 
}

.main-outer-category {
  overflow: auto;
  height: 100px;
  position: relative;
}

.sticky-subheader-outer-h {
    width: 100%;
    height: 63px;
    background-color: #171818;
    color: white;
    position: sticky !important;
    position: relative;
    top: 50px;
    left: 0;
    border-radius: 6px;
    z-index: 1;
}


.padding_custom{
  padding: 0px 11px !important;
}
.CateScroll {
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: #F7F7F7; */
  width: 100%;
  height: fit-content;
  z-index: 5;
  margin-top: -24px;
  margin-bottom: -36px;
  /* padding-top:5px */
}

section {
  padding: 0px !important;
}


@media only screen and (min-width: 320px) and (max-width: 576px) {
/* 
  .sticky-subheader-outer {
        position: static; 
        top: auto;
    } */
    .sticky-subheader-outer-over{
      top: 76px;
    }

    .sticky-subheader-outer{
      left: 0%;
      right: 0%;
      top: 68px;
    }
 
  .outer-category {
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 10px;
    top: -6px;
    width: 100%;
    left: 0px;
    height: 46px;
/* Custom scrollbar styling for WebKit browsers */
scrollbar-width: thin;  /* For Firefox */
scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */

/* For WebKit browsers (Chrome, Safari) */
&::-webkit-scrollbar {
  width: 0px;
  display: none ;
}

&::-webkit-scrollbar-thumb {
  background-color: #fffefe;
  display: none;
}

&::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  display: none;
}

  }

  .category-right-arrow {
    display: none;
  }

  .category-left-arrow {
    display: none;
  }
  .single-category
  {
    padding: 0px 12px 5px 5px;
  }
  .sticky-subheader-outer {
    border-radius: 0px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .outer-category {
    overflow-x: auto;
    margin-top: 33px;
    margin-bottom: 44px;

    /* Custom scrollbar styling for WebKit browsers */
    scrollbar-width: thin;  /* For Firefox */
    scrollbar-color: #ffffff #f0f0f0;  /* For Firefox */
  
    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 0px;
      display: none ;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #fffefe;
      display: none;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      display: none;
    }
  }

  .category-right-arrow {
    top: -165px;
    right: -97%;
   }
 
   .category-left-arrow  {
     left: 10px;
   }
}

@media only screen and (min-width: 768px) and (max-width: 982px) {
  .outer-category {
    overflow-x: auto;
    margin-top: 0px;
    margin-bottom: 44px;
  left: 32px;
  top: -37px;

    scrollbar-width: thin; 
    scrollbar-color: #ffffff #f0f0f0;  
  

    &::-webkit-scrollbar {
      width: 0px;
      display: none ;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #fffefe;
      display: none;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      display: none;
    }
  }

  .category-right-arrow {
   top: -165px;
   right: -97%;
  }

  .category-left-arrow  {
    left: 10px;
  }
  

}



@media  (min-width: 1024px) and (max-width: 1219px) {
  .outer-feature {
    overflow-x: auto;
  }
  
  .category-left-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0px;
    left: -12px;
    background: #fff !important;
    border-radius: 50%;
    position: relative;
    top: 65px;
    transform: translateY(-50%);
    z-index: 2;
}
.category-right-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  background: #fff;
  font-weight: 500;
  position: relative;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 1;
  /* right: 114%; */
  left: 98.5%;
  top: -37px;
}
.main-outer-category {
  background-color: #f7f7f7;
  margin-top: -27px;
}
.inner-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 60px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  /* margin-top: 9px; */
}

.custom-mobile {
  /* background-color: beige; */
}
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .custom-mobile {
    margin-left: 0px;
  }
  
  nav{
    max-height: 90px;
    top: 15px;
  }
}

@media only screen and (min-width:320px) and (max-width:380px)
{
  #mbl-cart-model-close-btn{
    top: 25px !important;
  }
}